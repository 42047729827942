import React from "react";
import "./about.css";

export default function About() {
  return (
    <div className="about-us">
      <div className="jumbotron"></div>
      <div className="about-content">
        <h4>مرحباً بكم في موقع مبدعو المستقبل للسفر والدراسة في الخارج</h4>
        <h6>رحلات سياحية لكافة البلدان</h6>
        <p>
          نحن نقدم رحلات سياحية إلى جميع أنحاء العالم. سواء كنت ترغب في استكشاف
          الشواطئ الجميلة في أستراليا، أو التجول في شوارع باريس  أو
          حتى التعرف على التاريخ والثقافة في الهند، فإننا نضمن لك تجربة لا
          تُنسى.
        </p>
        <h6>حجز التذاكر والتأمين والتأشيرات</h6>
        <p>
          نحن نقدم خدمات حجز التذاكر والتأمين والتأشيرات لجعل رحلتك أكثر سلاسة.
          نحن نتعامل مع جميع التفاصيل المعقدة بالنيابة عنك، حتى تتمكن من التركيز
          على التمتع برحلتك.
        </p>
        <h6>قسم الدراسة في الخارج</h6>
        <p>
          هل تفكر في الدراسة في الخارج؟ نحن نقدم مجموعة واسعة من المعلومات حول
          الجامعات والبرامج التعليمية المختلفة، وكيفية التقديم والحصول على قبول،
          وتكاليف الدراسة والمعيشة، وغيرها من المعلومات المهمة. سواء كنت تبحث عن
          درجة البكالوريوس أو الماجستير أو الدكتوراه، فإننا هنا لمساعدتك في كل
          خطوة على الطريق.
        </p>

        <h5>
          إذا كان لديك أي أسئلة أو استفسارات، فلا تتردد في الاتصال بنا. نحن هنا
          لمساعدتك في تحقيق أحلامك في السفر والدراسة في الخارج!
        </h5>
      </div>
    </div>
  );
}
